// import React, { useRef } from "react";
// import "./App.css";
// import { useEffect, useState } from "react";
// import { TbSend } from "react-icons/tb";
// import axios from "axios";
// import qs from "qs";
// import { RiRobot2Line } from "react-icons/ri";
// import TypingEffect from "./TypingEffect";

// function App() {
//   const [input, setInput] = useState("");
//   const [chatLog, setChatLog] = useState([]);
//   const containerRef = useRef(null);
//   const [histroyLog, setHistroyLog] = useState([]);
//   const [error, setError] = useState(null); // Error state
//   const [loader, serLoader] = useState(false);
//   const divRef = useRef(null);
//   const [isTyping, setIsTyping] = useState(false);
//   const textareaRef = useRef(null);
//   useEffect(() => {
//     const { current } = divRef;
//     current.scrollTop = current.scrollHeight;
//   }, [divRef]);

//   useEffect(() => {
//     // Adjust textarea height based on scroll height to accommodate new lines
//     textareaRef.current.style.height = "auto";
//     textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
//   }, [input]);

//   const handleSubmit = async (e) => {
//     setIsTyping(true);
//     e.preventDefault();
//     scrollBottom();
//     if (input.trim() === "") {
//       // console.log("input is empty");
//     } else {
//       // let data = qs.stringify({
//       //   // query: input,
//       //   message: input,
//       // });
//       let data = {
//         message: input,
//       };

//       let chatlogNew = [...chatLog, { user: "me", message: `${input}` }];
//       setInput("");
//       setChatLog(chatlogNew);

//       serLoader(true);
//       setError(null);

//       let lastTenMessages = histroyLog.slice(-6);
//       data.history = lastTenMessages;
//       // console.log("LTM : ",lastTenMessages)

//       // Axios request configuration
//       let config = {
//         method: "post",
//         maxBodyLength: Infinity,
//         url: "https://achilles.timothychristian.com/api/ask",
//         // url: "http://localhost:8000/api/ask",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         data: data,
//       };

//       // console.log("DAta : - - - ",data)

//       // Make the POST request using axios
//       axios
//         .request(config)
//         .then((response) => {
//           // Handle the response data
//           // console.log(JSON.stringify(response.data));
//           // console.log(JSON.stringify(response.data.response));
//           console.log("API RESPONSE : ", response);
//           const apiResponse = JSON.stringify(response.data.response);
//           const formattedText = apiResponse
//             .replace(/\n/g, "")
//             .replace(/^"|"$/g, "")
//             .replace(/\\"/g, '"'); // Replace \" with just "

//           let histroylogNew = [
//             ...histroyLog,
//             { role: "user", content: `${input}` },
//           ];
//           setHistroyLog(histroylogNew);

//           setHistroyLog([
//             ...histroylogNew,
//             { role: "assistant", content: `${formattedText}` },
//           ]);

//           setChatLog([
//             ...chatlogNew,
//             { user: "gpt", message: `${formattedText}` },
//           ]);

//           serLoader(false);
//           scrollBottom();
//         })
//         .catch((error) => {
//           setIsTyping(false);
//           // console.error(error);
//           setError(
//             "An error occurred while processing your request. Please try again"
//           ); // Set error
//           serLoader(false);
//         });
//     }
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === "Enter" && !e.shiftKey) {
//       e.preventDefault(); // Prevent new line

//       if (!isTyping) {
//         handleSubmit(e); // Call submit function
//       }
//     }
//   };

//   const scrollBottom = () => {
//     const { current } = divRef;
//     current.scrollTop = current.scrollHeight;
//   };

//   const handleTypingComplete = (isTyping) => {
//     setIsTyping(isTyping); // Enable input when typing is complete
//   };

//   return (
//     <div className="App">
//       <section className="chatbox">
//         {chatLog.length < 1 && (
//           <div className="intro_section">
//             <img src="/static/timtest.png" alt="" />
//             <h3>Achilles AI Assistant</h3>
//             <p>A parent's guide to all things Achilles. </p>
//           </div>
//         )}

//         <div ref={divRef} className="chat_log">
//           {chatLog.map((message, index) => (
//             <ChatMessage key={index} message={message} isTyping={isTyping} containerRef={divRef} onTypingComplete={handleTypingComplete} />
//           ))}

//           {loader && (
//             <div className={`chat_message chatgpt`}>
//               <div className="chat_message_center chatgpt loader">
//                 <div className={`avatar chatgpt`}>
//                   <RiRobot2Line color="#000" size={25} />
//                 </div>
//                 <div className={`message chatgpt loader`}>
//                   <img src="/static/response-loader.svg" alt="" width={100} />
//                 </div>
//               </div>
//             </div>
//           )}
//           {error && (
//             <div className={`chat_message chatgpt`}>
//               <div className="chat_message_center chatgpt loader">
//                 <div className={`avatar chatgpt`}>
//                   <RiRobot2Line color="#000" size={25} />
//                 </div>
//                 <div className={`message chatgpt loader`}>{error}</div>
//               </div>
//             </div>
//           )}
//         </div>

//         <div className="chat_input_holder">
//           <form onSubmit={handleSubmit} className="input_field_container">
//             <textarea
//               rows={1}
//               ref={textareaRef}
//               onKeyDown={handleKeyDown} // Trigger on "Enter"
//               placeholder="Ask a question"
//               value={input}
//               onChange={(e) => setInput(e.target.value)}
//               className="chat_input"
//             />
//             <button type="submit" className="send_icon btn" disabled={isTyping}>
//               <TbSend color="#5F606F" size={20} />
//             </button>
//           </form>
//         </div>
//       </section>
//     </div>
//   );
// }

// const ChatMessage = ({ message, isTyping, containerRef, onTypingComplete }) => {
//   const lines = message.message.split("\\n");

//   return (
//     <>
//       <div className={`chat_message ${message.user === "gpt" && "chatgpt"}`}>
//         <div className="chat_message_center ">
//           <div className={`avatar ${message.user === "gpt" && "chatgpt"}`}>
//             {message.user === "gpt" && <RiRobot2Line color="#000" size={25} />}
//           </div>
//           <div className={`message ${message.user === "gpt" && "chatgpt"}`}>
//             {message.user !== "gpt" ? (
//               lines.map((line, index) => <p key={index}>{line}</p>)
//             ) : (
//               <TypingEffect textArray={lines} containerRef={containerRef} onTypingComplete={onTypingComplete} />
//             )}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default App;


import React, { useRef } from "react";
import "./App.css";
import { useEffect, useState } from "react";
import { TbSend } from "react-icons/tb";
import axios from "axios";
import qs from "qs";
import { RiRobot2Line } from "react-icons/ri";
import TypingEffect from "./TypingEffect";

function App() {
  const [input, setInput] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [histroyLog, setHistroyLog] = useState([]);
  const [error, setError] = useState(null); // Error state
  const [loader, serLoader] = useState(false);
  const divRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {
    const { current } = divRef;
    current.scrollTop = current.scrollHeight;
  }, [divRef]);

  useEffect(() => {
    scrollBottom()
  }, [chatLog]);

  useEffect(() => {
    // Adjust textarea height based on scroll height to accommodate new lines
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }, [input]);

  const handleSubmit = async (e, command = null) => {
    setIsTyping(true);
    e?.preventDefault();

    
    if (command === "What is happening today at Timothy?") {
      let dynamicDate = new Date();
      const formatter = new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        timeZone: 'America/Chicago'
      });
      const formattedDate = formatter.format(dynamicDate);
      command = `What events / athletic games are happening on ${formattedDate}`;
    }

    const message = command || input.trim();
    if (message === "") return;


    let data = { message };
    let chatlogNew = [...chatLog, { user: "me", message }];
    setInput("");
    setChatLog(chatlogNew);

    serLoader(true);
    setError(null);

    let lastTenMessages = histroyLog.slice(-6);
    data.history = lastTenMessages;

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://achilles.timothychristian.com/api/ask",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const apiResponse = JSON.stringify(response.data.response);
        const formattedText = apiResponse
          .replace(/\n/g, "")
          .replace(/^"|"$/g, "")
          .replace(/\\"/g, '"'); // Replace \" with just "

        let histroylogNew = [
          ...histroyLog,
          { role: "user", content: message },
        ];
        setHistroyLog(histroylogNew);

        setHistroyLog([
          ...histroylogNew,
          { role: "assistant", content: formattedText },
        ]);

        setChatLog([
          ...chatlogNew,
          { user: "gpt", message: formattedText },
        ]);

        serLoader(false);
        scrollBottom();
      })
      .catch((error) => {
        setIsTyping(false);
        setError(
          "An error occurred while processing your request. Please try again"
        ); // Set error
        serLoader(false);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent new line
      if (!isTyping) {
        scrollBottom()
        handleSubmit(e);
      }
    }
  };

  const scrollBottom = () => {
    const { current } = divRef;
    current.scrollTop = current.scrollHeight;
  };

  const handleTypingComplete = (isTyping) => {
    setIsTyping(isTyping); // Enable input when typing is complete
  };

  const predefinedCommands = [
    { label: "Command 1", command: "What is happening today at Timothy?" },
    { label: "Command 2", command: "What is the middle school schedule on Wednesday?" },
    { label: "Command 3", command: "What's the minimum Carnegie credits to graduate?" },
    { label: "Command 4", command: "Is there any prohibited clothing?" },
    { label: "Command 5", command: "When is the Christmas Collage Concert?" },
  ];

  return (
    <div className="App">
      <section className="chatbox">
        {chatLog.length < 1 && (
          <>
            <div className="intro_section">
              <img src="/static/timtest.png" alt="" />
              <h3>Achilles AI Assistant</h3>
              <p>A parent's guide to all things Timothy</p>

              <div className="command_buttons">
                {predefinedCommands.map((cmd, index) => (
                  <button
                    key={index}
                    className="command_button"
                    onClick={() => handleSubmit(null, cmd.command)}
                  >
                    {cmd.command}
                  </button>
                ))}
              </div>
            </div>
          </>
        )}

        <div ref={divRef} className="chat_log">
          {chatLog.map((message, index) => (
            <ChatMessage
              key={index}
              message={message}
              isTyping={isTyping}
              containerRef={divRef}
              onTypingComplete={handleTypingComplete}
            />
          ))}

          {loader && (
            <div className={`chat_message chatgpt`}>
              <div className="chat_message_center chatgpt loader">
                <div className={`avatar chatgpt`}>
                <img
              src="/static/profile-picture.png"
              alt="Chatbot Avatar"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                objectFit: 'cover' 
                  }}
                />
                </div>
                <div className={`message chatgpt loader`}>
                  <img src="/static/response-loader.svg" alt="" width={100} />
                </div>
              </div>
            </div>
          )}
          {error && (
            <div className={`chat_message chatgpt`}>
              <div className="chat_message_center chatgpt loader">
                <div className={`avatar chatgpt`}>
                <img
              src="/static/profile-picture.png"
              alt="Chatbot Avatar"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                objectFit: 'cover'
              }}
            />
                </div>
                <div className={`message chatgpt loader`}>{error}</div>
              </div>
            </div>
          )}
        </div>

        <div className="chat_input_holder">
          <form onSubmit={handleSubmit} className="input_field_container">
            <textarea
              rows={1}
              ref={textareaRef}
              onKeyDown={handleKeyDown}
              placeholder="Ask a question"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="chat_input"
            />
            <button type="submit" className="send_icon btn" disabled={isTyping}>
              <TbSend color="#5F606F" size={20} />
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}

const ChatMessage = ({ message, isTyping, containerRef, onTypingComplete }) => {
  const lines = message.message.split("\\n");

  return (
    <div className={`chat_message ${message.user === "gpt" && "chatgpt"}`}>
      <div className="chat_message_center ">
        <div className={`avatar ${message.user === "gpt" && "chatgpt"}`}>
          {message.user === "gpt" && (
            <img
              src="/static/profile-picture.png"
              alt="Chatbot Avatar"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                objectFit: 'cover'
              }}
            />
          )}
        </div>
        <div className={`message ${message.user === "gpt" && "chatgpt"}`}>
          {message.user !== "gpt" ? (
            lines.map((line, index) => <p key={index}>{line}</p>)
          ) : (
            <TypingEffect
              textArray={lines}
              containerRef={containerRef}
              onTypingComplete={onTypingComplete}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default App;
