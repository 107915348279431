import React, { useState, useEffect, useRef } from "react";

const TypingEffect = ({ textArray, containerRef, onTypingComplete }) => {
  textArray = textArray?.filter((item) => item !== "" && item);
  console.log({textArray})
  const [displayedText, setDisplayedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentCharIndex, setCurrentCharIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < textArray.length) {
      if (currentCharIndex < textArray[currentIndex].length) {
        const interval = setInterval(() => {
          setDisplayedText(
            (prev) => prev + textArray[currentIndex][currentCharIndex]
          );
          setCurrentCharIndex((prev) => prev + 1);
        }, 5); // Typing speed for each letter

        return () => clearInterval(interval);
      } else {
        // Move to the next line
        setTimeout(() => {
          setDisplayedText((prev) => prev + "\n"); // Add a newline after completing the line
          setCurrentIndex((prev) => prev + 1);
          setCurrentCharIndex(0); // Reset character index for the next line
        }, 200); // Pause before starting the next line
      }
    } else if (currentIndex === textArray.length) {
      // Notify that typing is complete
      onTypingComplete(false);
    }
  }, [currentIndex, currentCharIndex]);

  useEffect(() => {
    // Scroll to the bottom of the container
    if (containerRef?.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [displayedText]);

  const renderWithFormatting = (text) => {
   // Split the text, but ensure that blocks starting with ``` are preserved as a single element
   const parts = text.split(/(\`\`\`[\s\S]*?\`\`\`|\*\*\*.*?\*\*\*|\*\*.*?\*\*|___.*?___|_.*?_|\`.*?\`|\#\#*\s.*?$|\[.*?\]\(.*?\))/)
   .filter((part) => part.trim() !== ""); // Filter out empty strings and whitespace-only strings

//  console.log({ parts });

      
      return parts?.length > 0
      ? parts.map((part, index) => {
        if (part.startsWith("***") && part.endsWith("***")) {
          // Bold and Italic (***)
          return (
            <strong key={index}>
              <em>{part.slice(3, -3)}</em> {/* Remove *** and make bold and italic */}
            </strong>
          );
        } else if (part.startsWith("**") && part.endsWith("**")) {
          console.log({ parts });
            // Bold
            return (
              <strong key={index}>
                {part.slice(2, -2)} {/* Remove ** and make bold */}
              </strong>
            );
          } else if (part.startsWith("_") && part.endsWith("_")) {
            // Italic
            return (
              <em key={index}>
                {part.slice(1, -1)} {/* Remove _ and make italic */}
              </em>
            );
          } else if (part.startsWith("###") && !part.startsWith("####")) {
            // Heading Level 3
            return (
              <span
                key={index}
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "black",
                  display: "block",
                  marginTop: "4px",
                }}
              >
                {part.slice(3).trim()} {/* Remove ### and trim */}
              </span>
            );
          } else if (part.startsWith("####")) {
            // Heading Level 4
            return (
              <span
                key={index}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "black",
                  display: "block",
                  marginTop: "2px",
                }}
              >
                {part.slice(4).trim()} {/* Remove #### and trim */}
              </span>
            );
          } else if (part.startsWith("##")) {
            // Heading Level 2
            return (
              <span
                key={index}
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "black",
                  display: "block",
                  marginTop: "6px",
                }}
              >
                {part.slice(2).trim()} {/* Remove ## and trim */}
              </span>
            );
          } else if (part.startsWith("#")) {
            // Heading Level 1 (Largest)
            return (
              <span
                key={index}
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "black",
                  display: "block",
                  marginTop: "8px",
                }}
              >
                {part.slice(1).trim()} {/* Remove # and trim */}
              </span>
            );
          } else if (part.startsWith("`") && !part.startsWith("``")) {
            // Code block (including backticks) - preserve it as a single block
            return (
              <pre
                key={index}
                style={{
                  backgroundColor: "rgb(199 203 213)",
                  color: "#f8f8f2",
                  padding: "10px",
                  borderRadius: "4px",
                  overflowX: "auto",
                  fontFamily: "monospace",
                  fontSize: "14px",
                }}
              >
                <code>{part.slice(1)}</code>{" "}
                {/* Remove the starting and ending triple backticks */}
              </pre>
            );
          } else if (part.startsWith("``")) {
            return null;
          } else if (
            part.startsWith("[") &&
            part.includes("](") &&
            part.endsWith(")")
          ) {
            // Links
            const linkText = part.slice(1, part.indexOf("]"));
            const url = part.slice(part.indexOf("(") + 1, -1);
            return (
              <a
                key={index}
                href={url}
                style={{ color: "blue", textDecoration: "underline" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {linkText}
              </a>
            );
          }

          return part; // Regular text
        })
      : null;
  };

  return (
    <div
      style={{
        whiteSpace: "pre-wrap",
        fontFamily: "monospace",
      }}
    >
      {displayedText.split("\n").map((line, index) => (
        <div key={index}>{renderWithFormatting(line)}</div>
      ))}
    </div>
  );
};

export default TypingEffect;